'use client'

import React from 'react'
import { MAX_TIME_TOKEN_VERIFICATION } from '@/config/constants'
import { SessionProvider } from 'next-auth/react'

export function NextSessionProvider({ children }) {
  return (
    <SessionProvider
      refetchInterval={MAX_TIME_TOKEN_VERIFICATION}
      refetchOnWindowFocus={true}>
      {children}
    </SessionProvider>)
}
