import { create } from 'zustand'
import { UserNotification, UserNotificationStatus } from '../../types'

export enum SelectorEnum {
  ALL = 'all',
  NEW = 'new',
}

type InAppStore = {
  totalNew: number
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  categoryId: number
  tab: SelectorEnum
  setTab: (tab: SelectorEnum) => void
  notifications: UserNotification[]
  allNotifications: UserNotification[]
  setCategoryId: (categoryId: number) => void
  setNotifications: (notifications: UserNotification[]) => void
}

export const useInAppStore = create<InAppStore>((set, get) => ({
  totalNew: 0,
  isLoading: false,
  tab: SelectorEnum.NEW,
  setTab: (data) => {
    const categoryId = get().categoryId
    const allNotifications = get().allNotifications
    const [notificationsFiltered, totalNew] = filterNotifications(
      allNotifications,
      categoryId,
      data
    )

    set({
      tab: data,
      notifications: notificationsFiltered,
      totalNew: totalNew,
    })
  },
  setIsLoading: (data) => set({ isLoading: data }),
  categoryId: 0,
  notifications: [],
  allNotifications: [],
  setCategoryId: (categoryId) => {
    const allNotifications = get().allNotifications
    const tab = get().tab
    const [notificationsFiltered, totalNew] = filterNotifications(
      allNotifications,
      categoryId,
      tab
    )

    set({
      categoryId: categoryId,
      notifications: notificationsFiltered,
      totalNew: totalNew,
    })
  },
  setNotifications: (data) => {
    const categoryId = get().categoryId
    const tab = get().tab

    data = data.map((notification) => {
      return {
        ...notification,
        typeInAppProps: {
          ...notification?.typeInAppProps,
          isNew: isNewNotification(notification),
        },
      }
    })

    const [notificationsFiltered, totalNew] = filterNotifications(
      data,
      categoryId,
      tab
    )

    set({
      notifications: notificationsFiltered,
      allNotifications: data,
      totalNew: totalNew,
    })
  },
}))

function filterNotifications(
  notifications: UserNotification[],
  categoryId: number,
  tab: SelectorEnum
): [UserNotification[], number] {
  const notificationsFiltered = notifications
    .filter(
      (notification) =>
        categoryId <= 0 || notification.categoryId === categoryId
    )
    .filter(
      (notification) =>
        tab === SelectorEnum.ALL || notification.typeInAppProps?.isNew
    )

  const totalNew =
    notificationsFiltered?.filter(
      (notification) => notification.typeInAppProps?.isNew
    )?.length || 0

  return [notificationsFiltered, totalNew]
}

function isNewNotification(notification: UserNotification) {
  const statuses =
    notification.userStatus.filter(
      (status) => status !== UserNotificationStatus.VIEWED
    ) || []

  return (
    statuses.length <= 0 ||
    ![
      UserNotificationStatus.MARK_AS_VIEWED,
      UserNotificationStatus.DISMISS,
      UserNotificationStatus.INTERACTED,
    ].includes(statuses[0])
  )
}
