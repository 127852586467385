'use client'
import { createContext, useCallback, useContext, useState } from 'react'
import { useSession } from '@repo/users'
import {
  PlayingVideoProps,
  useVideoStore,
  VideoProps,
} from '@/hooks/video-store'

export type VideoContextProps = {
  playVideo: ({}: PlayingVideoProps) => void
  reset: () => void
  videoTrack: (second: number, duration: number, url: string) => void
  videoError: (trackError: string) => void
  courseClass: VideoProps[]
}

const VideoContext = createContext<VideoContextProps>({
  playVideo: ({}: PlayingVideoProps) => {},
  reset: () => {},
  videoTrack: (second: number, duration: number, url: string) => {},
  videoError: (trackError: string) => {},
  courseClass: [],
})

export const useVideo = () => useContext(VideoContext)

export function VideoProvider({ children }: { children: React.ReactNode }) {
  const { session, isLoading: isSessionLoading } = useSession()
  const [isLoading, setIsLoading] = useState(false)

  const { courseClass, playVideo, reset, resetTimeVideo } = useVideoStore()

  const videoTrack = useCallback(
    async (seconds: number, duration: number, url: string) => {
      if (!session?.user?.id || courseClass.length === 0 || isLoading) return

      setIsLoading(true)
      try {
        const cc = courseClass.find((v) => v.mediaUrl === url)
        if (cc) {
          await fetch(`${process.env.NEXT_PUBLIC_EXP_URL}/tk/video`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: session.user.id,
              ...cc,
              playbackPosition: seconds,
              videoDuration: duration,
              watchTime: cc.isPlaying
                ? Date.now() - cc.startingTime + cc.watchTime
                : 0,
            }),
          })
          resetTimeVideo({
            mediaUrl: url || '',
          })
        }
      } catch (error) {
        console.error('Error processing videoTrack', error)
      } finally {
        setIsLoading(false)
      }
    },
    [courseClass, resetTimeVideo, isLoading, session?.user?.id]
  )

  const videoError = useCallback(
    async (trackError: string) => {
      if (!session?.user?.id) return

      try {
        await fetch(`${process.env.NEXT_PUBLIC_EXP_URL}/tk/video/error`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: session.user.id,
            error: trackError,
          }),
        })
      } catch (error) {
        console.error('Error processing error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [session?.user?.id]
  )

  return (
    <VideoContext.Provider
      value={{
        playVideo,
        courseClass,
        reset,
        videoTrack,
        videoError,
      }}>
      {children}
    </VideoContext.Provider>
  )
}
