import(/* webpackMode: "eager", webpackExports: ["MainBody"] */ "/vercel/path0/apps/web/src/app/components/main-body.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextSessionProvider"] */ "/vercel/path0/apps/web/src/app/components/next-session-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ServerStylesheet"] */ "/vercel/path0/apps/web/src/app/components/stitches.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["HubspotScript"] */ "/vercel/path0/apps/web/src/lib/hubspot-script.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/print.css");
;
import(/* webpackMode: "eager", webpackExports: ["VideoProvider"] */ "/vercel/path0/apps/web/src/providers/video/video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ABTestingProvider"] */ "/vercel/path0/backend/ab-testing/ui/hooks/ab_test.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/backend/notifications/contexts/web/notification-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrackingProvider"] */ "/vercel/path0/backend/tracking/contexts/tracking.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.3_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.77.2__react@18.2.0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.3_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.77.2__react@18.2.0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.3_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.77.2__react@18.2.0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.77.2/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.77.2/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
