import { z } from 'zod'
import {
  NotificationFilterTab,
  NotificationStatus,
  RuleEnum,
  RulePlanEnum,
} from './notification-types'

export const notificationAdminListFilterSchema = z.object({
  page: z.coerce.number().default(0),
  itemsPerPage: z.coerce.number().default(10),
  filterText: z.coerce.string().optional(),
  tab: z.nativeEnum(NotificationFilterTab).catch(NotificationFilterTab.ALL),
})
export const notificationAdminListCountFilterSchema = z.object({
  tab: z.nativeEnum(NotificationFilterTab).catch(NotificationFilterTab.ALL),
  filterText: z.coerce.string().optional(),
})

export const notificationUpsertRuleConfigFilterSchema = z.object({
  daysAfterRegistration: z.coerce.number().optional(),
  durationDays: z.coerce.number().optional(),
  plans: z.coerce.string().array().optional(),
})

export const notificationUpsertRuleFilterSchema = z.object({
  rule: z.nativeEnum(RuleEnum).catch(RuleEnum.SELECT),
  config: notificationUpsertRuleConfigFilterSchema.optional(),
})

export const notificationUpsertFilterSchema = z.object({
  id: z.coerce.number().optional(),
  title: z.coerce.string().optional(),
  body: z.coerce.string().optional(),
  types: z.coerce.string().array().optional(),
  categoryId: z.coerce.number().optional(),
  status: z
    .nativeEnum(NotificationStatus)
    .catch(NotificationStatus.DRAFT)
    .optional(),
  platformsIds: z.coerce.number().array().optional(),
  examsIds: z.coerce.number().array().optional(),
  enabledFrom: z.coerce.date().nullable().optional(),
  enabledTo: z.coerce.date().nullable().optional(),
  rules: notificationUpsertRuleFilterSchema.array().optional(),
})

export const checkSegmentationFilterSchema = z.object({
  platformsIds: z.coerce.number().array(),
  examsIds: z.coerce.number().array(),
})

export const copyNotificationFilterSchema = z.object({
  id: z.coerce.number(),
})
