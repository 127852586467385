'use client'
import { createContext, useContext, useEffect } from 'react'
import { NotificationsListResponse } from '../../types'
import { userListNotification } from '../../actions/user-list-notification'
import useSWR from 'swr'
import { useSession } from '@repo/users'
import { NotificationState } from '../types'
import { useInAppStore } from '../../ui/stores/in-app-store'

const NotificationListContext = createContext<
  NotificationState<NotificationsListResponse>
>({
  data: {},
  mutate: () => {},
  isLoading: false,
  error: null,
  isValidating: false,
})

export const useList = () => useContext(NotificationListContext)

export function ListProvider({ children }: { children: React.ReactNode }) {
  const { setNotifications, setIsLoading } = useInAppStore()
  const { session } = useSession()
  const {
    data: notifications,
    mutate,
    isLoading,
    error,
    isValidating,
  } = useSWR<NotificationsListResponse>(
    `notifications-list-${session?.user?.id}`,
    async () => await userListNotification(session?.user?.id),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 600000, //10 minutes
    }
  )

  useEffect(() => {
    if (notifications) {
      setNotifications(notifications.IN_APP || [])
    }
  }, [notifications])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  return (
    <NotificationListContext.Provider
      value={{
        data: notifications || {},
        mutate,
        isLoading,
        error,
        isValidating,
      }}>
      {children}
    </NotificationListContext.Provider>
  )
}
