// Declare gTM dataLayer array.
declare global {
  interface Window {
    // @ts-ignore
    dataLayer: any[]
    gtag: any
  }
}

type GTMConsent = {
  ad_storage?: 'granted' | 'denied'
  analytics_storage?: 'granted' | 'denied'
  functionality_storage?: 'granted' | 'denied'
  personalization_storage?: 'granted' | 'denied'
  security_storage?: 'granted' | 'denied'
}

function track(payload = {}) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    ...payload,
  })
}

export const updateConsent = (payload: GTMConsent) => {
  if (typeof window.gtag === 'function') {
    window.gtag('consent', 'update', {
      ...payload,
    })
  }
}

export const updateAllConsentToGranted = () => {
  updateConsent({
    ad_storage: 'granted',
    analytics_storage: 'granted',
    functionality_storage: 'granted',
    personalization_storage: 'granted',
    security_storage: 'granted',
  })
}

export const pageViewAnalytics = (url: string) => {
  // console.log('pageViewAnalytics', url)
  track({
    event: 'pageview',
    page: url,
  })
}

export const eventAnalytics = ({
  name,
  data = {},
}: {
  name: string
  data?: Record<string, string | number | null | undefined | object>
}) => {
  track({
    event: name,
    ...data,
  })
}
