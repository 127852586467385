'use client'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useTracking } from '@/screens/common/use-tracking'
import { useSession } from 'next-auth/react'
import { usePathname, useSearchParams } from 'next/navigation'

export function TrackingComponent({ children }: PropsWithChildren) {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const tracking = useTracking()
  const [loaded, setLoaded] = useState(false)
  const { data: session, status } = useSession()

  useEffect(() => {
    if (
      status === 'authenticated' &&
      session &&
      session.user &&
      session.user.platform
    ) {
      tracking.updateUserProperties({
        platform: session?.user?.platform,
        opoName: session?.user?.opoName!,
        avatarUrl: session?.user?.image?.url,
        avatarBkg: session?.user?.image?.background,
      })
    }
  }, [session, status, tracking])

  const completePath = `${pathname}${
    searchParams && searchParams?.toString()
      ? `?${searchParams?.toString()}`
      : ''
  }`

  useEffect(() => {
    if (completePath) {
      tracking.trackPageView(completePath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completePath])

  useEffect(() => {
    if (pathname && tracking && !loaded) {
      tracking.initTracking()
      setLoaded(true)
    }
  }, [pathname, tracking, loaded])

  return <>{children}</>
}
