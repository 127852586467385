import { checkToken } from '@/services/api/auth/validate-token'
import { usePathname } from 'next/navigation'
import { useCallback, useEffect } from 'react'
import { useFetcher } from 'utils'
import { doLogout } from '@/hooks/use-logout'

const tokenSelector = (state) => state.authToken

const whiteList = [
  '/autorizar',
  '/account/login',
  '/account/login/two-factor',
  '/account/login/phone-verify',
  '/account/create',
  '/account/forgot',
  '/account/password',
  '/account/email',
  '/activation',
  '/learn',
]
const setTokenSelector = (state) => state.setAuthToken

export default function useCheckSubscription() {
  const pathname = usePathname()
  const stateToken = useFetcher(tokenSelector)
  const setToken = useFetcher(setTokenSelector)

  const handleValidation = useCallback(
    async (token: string | null) => {
      // Only run if the entry url is not the auth page
      if (whiteList.find((x) => x === pathname)) {
        return null
      }

      if (!token) {
        doLogout()
        return null
      }

      const tokenChecked = await checkToken(token)

      if (!tokenChecked || !tokenChecked.isValid) {
        setToken(null)
        doLogout()
      }
    },
    [setToken, pathname]
  )

  useEffect(() => {
    handleValidation(stateToken)
  }, [stateToken, handleValidation])
}
