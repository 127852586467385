import { Descendant } from 'slate'

export type PlatformsType = {
  id: number
  name: string
  items: {
    id: number
    name: string
  }[]
}

export enum CategoriesIds {
  NEW = 1,
  UPGRADE = 2,
  ANNOUNCEMENT = 3,
  SOON = 4,
  CONVO = 5,
  NEW_COMMENT = 6,
}
export interface NotificationsListResponse {
  IN_APP?: UserNotification[]
  EMAIL?: UserNotification[]
  TOP_BAR?: UserNotification[]
  MODAL_CENTRAL?: UserNotification[]
  PUSH?: UserNotification[]
}

export type NotificationCategory = {
  id: number
  content: string
  backgroundColor: string
  contentColor: string
}

export type AddNotificationRepository = {
  title: string
  body: string
  categoryId?: number
  status: NotificationStatus
  types: string[]
  platformsIds: number[]
  examsIds: number[]
  enabledFrom?: Date
  enabledTo?: Date
  validFor?: number
  usersId?: number[]
  ruleAfterRegistration?: RuleConfigDaysAfterRegistration
  rulePlan?: string[]
}

export type Notification = {
  id?: number
  title: string
  body: Descendant[]
  types: string[]
  categoryId?: number
  category?: NotificationCategory
  status: NotificationStatus
  platformsIds: number[]
  platforms?: PlatformsNotification[]
  exams?: ExamNotification[]
  examsIds: number[]
  enabledFrom?: Date
  enabledTo?: Date
  createdAt?: Date
  rules?: RuleType[]
}

export enum RuleEnum {
  SELECT = 'Seleccionar...',
  DAYS_AFTER_REGISTRATION = 'Aparece tras ',
  PLAN = 'Con el/los planes',
}

export enum RulePlanEnum {
  MONTH = 'mensual',
  QUARTERLY = 'trimestral',
  BIANNUAL = 'semestral',
  ANNUAL = 'anual',
  ELITE = 'elite',
}

export type RuleType = {
  key: string
  rule: RuleEnum
  config?: RuleConfigDaysAfterRegistration | RuleConfigPlan
}

export type RuleConfigDaysAfterRegistration = {
  daysAfterRegistration: number
  durationDays?: number
}
export type RuleConfigPlan = {
  plans: RulePlanEnum[]
}

export type UserNotification = Notification & {
  id: number
  userStatus: UserNotificationStatus[]
  userReaction?: string
  typeInAppProps?: {
    isNew: boolean
  }
}

export type AdminNotificationRepository = Notification & {
  id: number
  userStatus: {
    userId: number
    status: UserNotificationStatus
  }[]
  userReactions: {
    userId: number
    status: UserNotificationReaction
  }[]
}

export type AdminNotification = AdminNotificationRepository & {
  currentState: NotificationCurrentState
}

export type PlatformsNotification = {
  id: number
  name: string
}

export type ExamNotification = {
  id: number
  name: string
}

export type GetUserNotifications = {
  userId: number
  platformId: number
  examId: number
}

export type UpdateUserNotificationStatus = {
  userId: number
  notificationIds: number[]
  type: string
  platformId: number
  examId: number
  status: UserNotificationStatus
}
export type FindAndUpdateNotificationsByBody = {
  userId: number
  body: string
  platformId: number
  examId: number
}

export enum NotificationCurrentState {
  VISIBLE = 'active',
  EXPIRED = 'expired',
  SCHEDULED = 'scheduled',
  DRAFT = 'draft',
}

export enum NotificationStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  DELETED = 'DELETED',
}
export enum UserNotificationStatus {
  NOT_SEEN = 'NOT_SEEN',
  VIEWED = 'VIEWED',
  MARK_AS_VIEWED = 'MARK_AS_VIEWED',
  MARK_AS_UNVIEWED = 'MARK_AS_UNVIEWED',
  DISMISS = 'DISMISS',
  INTERACTED = 'INTERACTED',
  REMOVED = 'REMOVED',
}
export enum UserNotificationReaction {
  DISLIKE = 'DISLIKE',
  NEUTRAL = 'NEUTRAL',
  LIKE = 'LIKE',
}

export enum NotificationTypes {
  IN_APP = 'IN_APP',
  EMAIL = 'EMAIL',
  TOP_BAR = 'TOP_BAR',
  MODAL_CENTRAL = 'MODAL_CENTRAL',
  PUSH = 'PUSH',
  AUTO = 'AUTO',
}

export enum NotificationOptions {
  TEMPORARY = 'TEMPORARY',
  DAYS_AFTER_REGISTRATION = 'DAYS_AFTER_REGISTRATION',
  DAYS_POST_REGISTRATION = 'DAYS_POST_REGISTRATION',
}

export type UsersFromNotification = {
  notificationId: number
  examIds: number[]
  platformIds: number[]
  rules?: RuleType[]
}

export type UserFromNotificationResponse = {
  notificationId: number
  subject: string
  body: string
  users: {
    userId: number
    email: string
  }[]
}

export type NotificationsFilter = {
  status?: {
    in?: NotificationStatus[]
    notIn?: NotificationStatus[]
  }
  enabledTo?: {
    lte?: Date
    gte?: Date
  }
  enabledFrom?: {
    lte?: Date
    gte?: Date
  }
}

export enum NotificationFilterTab {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
}
