'use client'
import { useEffect, useState } from 'react'
import { useSession } from '@repo/users'
import { useExperimentStore } from '../stores/experiment-store'
import { ExperimentsDto } from '../../types/experiment-dto'

export function ABTestingProvider({ children }: { children: React.ReactNode }) {
  const { session, isLoading: isSessionLoading } = useSession()
  const [isLoading, setIsLoading] = useState(false)
  const { shouldSync, getExperimentsBodyToRequest, setExperiments } =
    useExperimentStore()

  const requestExperiments = async (
    userId: number,
    experiments: ExperimentsDto[]
  ) => {
    let response: ExperimentsDto[] = []
    try {
      setIsLoading(true)

      if (process.env.NEXT_PUBLIC_EXP_URL) {
        const data = await fetch(
          `${process.env.NEXT_PUBLIC_EXP_URL}/ab/get` as string,
          {
            method: 'POST',
            body: JSON.stringify({ experiments, userId }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
          }
        )

        if (data && data.status >= 200 && data.status < 300) {
          const apiResponse = (await data.json()) as ExperimentsDto[]

          if (apiResponse) {
            response = [...apiResponse]
          }
        }
      }
    } catch (e) {
    } finally {
      setIsLoading(false)
      setExperiments(response)
    }
  }

  useEffect(() => {
    if (session && session.user?.id && shouldSync()) {
      const body = getExperimentsBodyToRequest()
      setIsLoading(true)

      requestExperiments(session.user.id, body)
    }
  }, [session, shouldSync])

  return <>{children}</>
}
