'use client'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export type VideoProps = {
  mediaUrl: string
  isPlaying: boolean
  startingTime: number
  watchTime: number
}

export type PlayingVideoProps = {
  mediaUrl: string
  isPlaying: boolean
}
export type ResetTimeVideoProps = {
  mediaUrl: string
}

export type VideoStore = {
  courseClass: VideoProps[]
  playVideo(event: VideoProps): void
  resetTimeVideo(event: ResetTimeVideoProps): void
  reset(): void
}

export const useVideoStore = create<VideoStore>()(
  persist<VideoStore>(
    (set) => ({
      courseClass: [],
      reset: () => {
        set({ courseClass: [] })
      },
      playVideo: (playVideo: PlayingVideoProps) => {
        set((state) => {
          const videoExists = state.courseClass.find(
            (e) => e.mediaUrl === playVideo.mediaUrl
          )

          if (videoExists) {
            if (playVideo.isPlaying && videoExists.isPlaying) {
              return state
            }
            // Si el video ya existe, lo actualizamos
            return {
              courseClass: state.courseClass.map((vid) =>
                vid.mediaUrl === playVideo.mediaUrl
                  ? {
                      ...vid,
                      isPlaying: playVideo.isPlaying,
                      startingTime: playVideo.isPlaying ? Date.now() : 0,
                      watchTime: !playVideo.isPlaying
                        ? Date.now() -
                          videoExists.startingTime +
                          videoExists.watchTime
                        : videoExists.watchTime,
                    }
                  : vid
              ),
            }
          } else {
            // Si el video no existe, lo añadimos
            return {
              courseClass: [
                ...state.courseClass,
                {
                  mediaUrl: playVideo.mediaUrl,
                  isPlaying: playVideo.isPlaying,
                  startingTime: playVideo.isPlaying ? Date.now() : 0,
                  watchTime: 0,
                },
              ],
            }
          }
        })
      },
      resetTimeVideo: ({ mediaUrl }: ResetTimeVideoProps) => {
        set((state) => ({
          courseClass: state.courseClass.map((vid) =>
            vid.mediaUrl === mediaUrl
              ? {
                  ...vid,
                  startingTime: vid.isPlaying ? Date.now() : vid.startingTime,
                  watchTime: vid.isPlaying ? 0 : vid.watchTime,
                }
              : vid
          ),
        }))
      },
    }),
    {
      name: 'vdo-st',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
