'use client'
import Script from 'next/script'
import { usePathname } from 'next/navigation'
import { useCallback, useEffect } from 'react'
import { create } from 'zustand'
import { useMutationObserver } from '@react-hooks-library/core'

const blackList = [
  '/autorizar',
  '/account/login',
  '/account/email',
  '/account/password',
  '/account/forgot',
  '/preguntas',
  '/resultados',
  '/preparar',
]

export const useHubspotStore = create((set) => ({
  isOpen: false,
  setOpen: (isOpen: boolean) => {
    set({ isOpen })
  },
}))

export function WatchChatFlowHubspot() {
  const pathname = usePathname()
  const isOpen = useHubspotStore((state: any) => state.isOpen)

  useMutationObserver(
    document?.body,
    () => {
      const container = document.getElementById(
        'hubspot-messages-iframe-container'
      )

      if (container && pathname) {
        container.style.visibility =
          blackList.includes(pathname) || isOpen ? 'hidden' : 'visible'
      }
    },
    { attributes: true, childList: true }
  )

  const onChange = useCallback(() => {
    const container = document.getElementById(
      'hubspot-messages-iframe-container'
    )
    if (container && pathname) {
      container.style.visibility =
        blackList.includes(pathname) || isOpen ? 'hidden' : 'visible'
    }
  }, [pathname, isOpen])

  useEffect(() => {
    onChange()
  }, [pathname, isOpen, onChange])

  return null
}

export function HubspotScript() {
  return (
    <Script
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      strategy="afterInteractive"
      src="//js.hs-scripts.com/26850836.js"
    />
  )
}
